import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Vue Google Maps */
import VueGoogleMaps from "@fawmi/vue-google-maps";

/* Pinia */
import { createPinia } from "pinia";

/* Analytics */
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

/* Firebase */
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyAg_szPCQeBLaaRvImOCaSuxt376BICMhE",
  authDomain: "onespot-live.firebaseapp.com",
  projectId: "onespot-live",
  storageBucket: "onespot-live.appspot.com",
  messagingSenderId: "330375700241",
  appId: "1:330375700241:web:9e6ad99a744d5fb885b6b0",
  measurementId: "G-EKC4QQ2ESF",
};
initializeApp(firebaseConfig);

FirebaseAnalytics.initializeFirebase(firebaseConfig);

const app = createApp(App)
  .use(IonicVue, {
    swipeBackEnabled: false,
    mode: "ios",
  })
  .use(router)
  .use(createPinia())
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyAqJ6-mTeNik7O5Riis2mlaSBxlFnsXdsE",
    },
  });

router.isReady().then(() => {
  app.mount("#app");
});
