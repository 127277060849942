import { defineStore } from "pinia";

import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { FirebaseFirestore } from "@capacitor-firebase/firestore";

import router from "@/router";

import authComp from "@/composables/authComp";
const { formatLoginAuthErrors } = authComp();
import generalAppComp from "@/composables/generalAppUtils";
const { showMessage } = generalAppComp();
import appComp from "@/composables/appComp";
const { mapPublicPromoter, mapPrivatePromoter } = appComp();

// TYPES
import AppUser from "@/types/appUser";
import OrganizerUser from "@/types/organizerUser";
import PublicPromoter from "@/types/PublicPromoter";
import PrivatePromoter from "@/types/PrivatePromoter";

// COMPOSABLES
import firebaseFunctionsComp from "@/composables/firebaseFunctionsComp";
const { callFirebaseFunction } = firebaseFunctionsComp();

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    userData: <AppUser | null>null,
    organizerData: <OrganizerUser | null>null,
    userMetaData: <any>undefined,
    userPromoterRequests: <PublicPromoter[]>[],
    userPrivatePromotions: <PrivatePromoter[]>[],
    adminOriginalUID: "",
    isAuthStateListenerActive: false,
  }),
  actions: {
    async loginUser(email: string, password: string) {
      try {
        await FirebaseAuthentication.signInWithEmailAndPassword({
          email: email,
          password: password,
        });
      } catch (err: any) {
        await showMessage("danger", formatLoginAuthErrors(err.code));
      }
    },
    async loginUserWithGoogle() {
      try {
        const google = await FirebaseAuthentication.signInWithGoogle();
        if (google) this.userMetaData = google.user;
      } catch (err: any) {
        await showMessage(
          "danger",
          "Ocurrió un error al iniciar sesión con Google."
        );
      }
    },
    async registerUserWithEmail(usrData: {
      name: string;
      lastName: string;
      email: string;
      password: string;
      rePassword: string;
      dateOfBirth: string;
    }) {
      let usrReg;
      try {
        localStorage.setItem("osUserRole", "register");
        usrReg = await FirebaseAuthentication.createUserWithEmailAndPassword({
          email: usrData.email,
          password: usrData.password,
        });
        if (usrReg) FirebaseAuthentication.sendEmailVerification();
        const newUserData: AppUser = {
          dateOfBirth: usrData.dateOfBirth,
          email: usrData.email,
          eventsFollowing: [],
          isRegistrationComplete: false,
          lastName: usrData.lastName,
          name: usrData.name,
          notifications: [],
          profile: "",
          promotersFollowing: [],
          tags: [],
          type: "user",
        };
        if (usrReg && usrReg["user"])
          await this.createUserInDb(usrReg.user.uid, newUserData);
      } catch (err: any) {
        await showMessage("danger", formatLoginAuthErrors(err.code));
        return;
      }
    },

    async registerOrganizerWithEmail(email: string, password: string) {
      try {
        const user =
          await FirebaseAuthentication.createUserWithEmailAndPassword({
            email: email,
            password: password,
          });
        this.userMetaData = user.user;
      } catch (err: any) {
        await showMessage("danger", formatLoginAuthErrors(err.code));
      }
    },

    async createUserInDb(uid: string, newUser: OrganizerUser | AppUser) {
      try {
        await FirebaseFirestore.setDocument({
          reference: `users/${uid}`,
          data: { ...newUser },
        });
        showMessage(
          "success",
          "Tu cuenta ha sido creada con éxito, te hemos enviado un correo de verificación de email."
        );
        await this.logoutUser();
        router.push("/");
      } catch (err) {
        showMessage("danger", "Ocurrió un error al registrar tus datos.");
      }
    },

    async logoutUser(back = false) {
      try {
        await FirebaseAuthentication.signOut();
        localStorage.setItem("osUserRole", "no-role");
        this.userMetaData = undefined;
        this.userData = null;
        this.organizerData = null;
      } catch (err) {
        console.log("Error login out user.");
      }
    },

    async registerUserAsUser() {
      const newUserData: AppUser = {
        dateOfBirth: "",
        email: this.userMetaData.email,
        eventsFollowing: [],
        isRegistrationComplete: false,
        lastName: this.userMetaData.displayName.split(" ")[1] || "",
        name: this.userMetaData.displayName.split(" ")[0] || "",
        notifications: [],
        profile: this.userMetaData.photoUrl,
        promotersFollowing: [],
        tags: [],
        type: "user",
      };
      try {
        await FirebaseFirestore.setDocument({
          reference: `users/${this.userMetaData.uid}`,
          data: newUserData,
        });
        this.userData = newUserData;
      } catch (err) {
        showMessage("danger", "Ocurrió un error al registrar tus datos.");
      }
    },

    async registerUserAsOrganizer() {
      const newUserData: AppUser = {
        dateOfBirth: "",
        email: this.userMetaData.additionalUserInfo.profile.email,
        eventsFollowing: [],
        isRegistrationComplete: false,
        lastName: this.userMetaData.additionalUserInfo.profile.family_name,
        name: this.userMetaData.additionalUserInfo.profile.given_name,
        notifications: [],
        profile: this.userMetaData.additionalUserInfo.profile.picture,
        promotersFollowing: [],
        tags: [],
        type: "user",
      };
      try {
        await FirebaseFirestore.setDocument({
          reference: `users/${this.userMetaData.user.uid}`,
          data: newUserData,
        });
        this.logoutUser();
        router.push("/pre-login");
      } catch (err) {
        showMessage("danger", "Ocurrió un error al registrar tus datos.");
      }
    },

    async checkIfAuthenticated() {
      if (this.isAuthStateListenerActive) return;
      localStorage.setItem("osUserRole", "no-role");

      // LISTEN FOR AUTH STATE CHANGE
      FirebaseAuthentication.addListener("authStateChange", async (user) => {
        this.isAuthStateListenerActive = true;
        if (localStorage.getItem("osUserRole") == "register") return;

        // DECODE FUNCTION
        const decodeJWT = (token: string) => {
          const base64Url = token.split(".")[1];
          const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
          const jsonPayload = decodeURIComponent(
            window
              .atob(base64)
              .split("")
              .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join("")
          );
          return JSON.parse(jsonPayload);
        };
        // DECODE FUNCTION
        if (user.user) {
          const tokenResult = await FirebaseAuthentication.getIdToken({
            forceRefresh: true,
          });
          const userClaims = decodeJWT(tokenResult.token);
          let claimsRole = userClaims.user_role;
          const snapshot = await this.getUserData(user.user.uid);
          if (snapshot.data) this.setUserData(snapshot.data, claimsRole);
          else {
            await this.registerUserAsUser();
            claimsRole = "user";
          }
          if (claimsRole != "user") {
            this.logoutUser();
            await showMessage(
              "danger",
              "Lo sentimos, no puedes iniciar sesión con este usuario."
            );
            return;
          }
          const emailVerified = user.user.emailVerified;
          if (!emailVerified) {
            await showMessage(
              "success",
              "Para iniciar sesión, primero debes validar tu dirección de correo electrónico."
            );
            this.logoutUser();
            return;
          }
          localStorage.setItem("osUserRole", claimsRole || "no-role");
          this.userMetaData = user.user;
        } else {
          localStorage.setItem("osUserRole", "no-role");
          if (
            window.location.href.includes("/buy/") ||
            window.location.href.includes("/tickets")
          )
            router.push("/");
        }
      });
    },

    setUserData(data: any, role: string) {
      if (role == "user") {
        const usr: AppUser = {
          name: data.name,
          lastName: data.lastName,
          dateOfBirth: data.dateOfBirth,
          email: data.email,
          eventsFollowing: data.eventsFollowing,
          isRegistrationComplete: data.isRegistrationComplete,
          notifications: data.notifications,
          profile: data.profile,
          promotersFollowing: data.promotersFollowing,
          tags: data.tags,
          type: data.type,
        };
        if (data.canScanEvents) usr.canScanEvents = data.canScanEvents;
        if (data.promoterId && data.promoterBankInfo) {
          usr.promoterId = data.promoterId;
          usr.promoterBankInfo = {
            bank: data.promoterBankInfo.bank,
            acc: data.promoterBankInfo.account,
            name: data.promoterBankInfo.name,
            type: data.promoterBankInfo.type,
            cellphone: data.promoterBankInfo.cellphone,
            ruc: data.promoterBankInfo.ruc,
          };
        }
        this.userData = usr;
      }
      if (role == "organizer") {
        const org: OrganizerUser = {
          name: data.name,
          adminEmail: data.adminEmail,
          adminName: data.adminName,
          adminLastname: data.adminLastname || data.adminLastName,
          ruc: data.ruc,
          followers: data.followers,
          logo: data.logo,
          instagram: data.instagram,
          noticeOfOperations: data.noticeOfOperations,
          space: data.space,
          status: data.status,
          type: data.type,
          bank: data.bank,
          account: data.account,
          accountType: data.accountType,
          accountName: data.accountName,
        };
        this.organizerData = org;
      }
    },

    async getUserData(uid: string) {
      const { snapshot } = await FirebaseFirestore.getDocument({
        reference: `users/${uid}`,
      });
      return snapshot;
    },

    async getUserPromoterRequests(promoterId: string) {
      const { snapshots } = await FirebaseFirestore.getCollection({
        reference: "promoters",
        compositeFilter: {
          type: "and",
          queryConstraints: [
            {
              type: "where",
              fieldPath: "promoterId",
              opStr: "==",
              value: promoterId,
            },
            {
              type: "where",
              fieldPath: "type",
              opStr: "==",
              value: "public",
            },
          ],
        },
      });
      this.userPromoterRequests = [];
      snapshots.forEach((request) => {
        this.userPromoterRequests.push(
          mapPublicPromoter(request.data, request.id)
        );
      });
    },

    async getUserPrivatePromotions(promoterId: string) {
      const { snapshots } = await FirebaseFirestore.getCollection({
        reference: "promoters",
        compositeFilter: {
          type: "and",
          queryConstraints: [
            {
              type: "where",
              fieldPath: "promoterId",
              opStr: "==",
              value: promoterId,
            },
            {
              type: "where",
              fieldPath: "type",
              opStr: "==",
              value: "private",
            },
          ],
        },
      });
      this.userPrivatePromotions = [];
      snapshots.forEach((request) => {
        this.userPrivatePromotions.push(
          mapPrivatePromoter(request.data, request.id)
        );
      });
    },

    async updateUserData(data: any, showConfirmation = false) {
      const id = this.userMetaData.uid;
      try {
        await FirebaseFirestore.updateDocument({
          reference: `users/${id}`,
          data: data,
        });
        if (showConfirmation)
          await showMessage("success", "Tus datos fueron guardados con éxito.");
      } catch (err) {
        await showMessage(
          "danger",
          "Ocurrió un error al actualizar tus datos."
        );
      }
    },

    async promoterIdValidation(
      id: string,
      uid: string,
      bank: string,
      acc: string,
      name: string,
      type: string,
      cellphone: string,
      ruc: string
    ) {
      try {
        const resp = await callFirebaseFunction("isPromoterIdAvailable", {
          id: id,
          uid: uid,
          bank: bank,
          account: acc,
          name: name,
          type: type,
          cellphone: cellphone,
          ruc: ruc,
        });
        if (resp?.data == "id-not-av") alert("Este ID ya esta ocupado.");
        if (resp?.data == "ok" && this.userData) {
          this.userData.promoterId = id;
          this.userData.promoterBankInfo = {
            bank: bank,
            acc: acc,
            name: name,
            type: type,
            cellphone: cellphone,
            ruc: ruc,
          };
          router.push("/promoter-panel?registered=true");
          showMessage("success", "Hemos guardado tus datos con éxito.");
        }
      } catch (err) {
        alert("Ocurrió un error.");
      }
    },

    async followOrganizer(action: "follow" | "unfollow", organizerId: string) {
      if (!this.userData) return;
      if (action == "follow") {
        this.userData?.promotersFollowing.push(organizerId);
      }
      if (action == "unfollow") {
        this.userData.promotersFollowing =
          this.userData.promotersFollowing.filter((org) => org != organizerId);
      }
      await FirebaseFirestore.updateDocument({
        reference: `users/${this.userMetaData.uid}`,
        data: { promotersFollowing: [...this.userData.promotersFollowing] },
      });
      callFirebaseFunction("updateOrganizerFollowers", {
        organizerId: organizerId,
      });
      return "ok";
    },

    async sendPasswordReset(email: string) {
      try {
        await FirebaseAuthentication.sendPasswordResetEmail({
          email: email,
        });
        showMessage("success", "Hemos enviado el correo satisfactóriamente.");
        return "ok";
      } catch (err) {
        showMessage("danger", "Ocurrió un error.");
      }
    },
  },
});
