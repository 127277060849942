import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

import UsersAppLayout from "@/layouts/UsersAppLayout.vue";
import GenericLayout from "@/layouts/GenericLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: UsersAppLayout,
    children: [
      {
        path: "/",
        component: () => import("@/views/UsersApp/HomeView.vue"),
        meta: {
          roles: ["user", "no-role"],
        },
      },
      {
        path: "event/:eventId",
        component: () => import("@/views/UsersApp/EventView.vue"),
        meta: {
          roles: ["user", "no-role"],
        },
      },
      {
        path: "tickets",
        component: () => import("@/views/UsersApp/TicketsView.vue"),
        meta: {
          roles: ["user"],
        },
      },
      {
        path: "buy/:eventId",
        component: () => import("@/views/UsersApp/BuyTicketsView.vue"),
        meta: {
          roles: ["user", "no-role"],
        },
      },
      {
        path: "organizer/:orgId",
        component: () => import("@/views/UsersApp/OrganizersView.vue"),
        meta: {
          roles: ["user", "no-role"],
        },
      },
      {
        path: "ticket/:ticketId/:type/:dateTime/:eventId",
        component: () => import("@/views/UsersApp/TicketView.vue"),
        meta: {
          roles: ["user", "no-role"],
        },
      },
    ],
  },
  {
    path: "/",
    component: GenericLayout,
    children: [
      {
        path: "forgot-password",
        component: () => import("@/views/Authentication/ForgotPassword.vue"),
        meta: {
          roles: ["no-role"],
        },
      },
      {
        path: "register-with-email",
        component: () =>
          import("@/views/Authentication/RegistrationWithEmail.vue"),
        meta: {
          roles: ["no-role"],
        },
      },
      {
        path: "redeem/:orderId",
        component: () => import("@/views/UsersApp/RedeemYappyPurchase.vue"),
        meta: {
          roles: ["no-role", "user"],
        },
      },
      {
        path: "terms-and-conditions/user-register",
        component: () => import("@/views/UsersApp/TsAndCsUserRegisterView.vue"),
        meta: {
          roles: ["no-role", "user"],
        },
      },
      {
        path: "terms-and-conditions/organizer-register",
        component: () =>
          import("@/views/UsersApp/TsAndCsOrganizerRegisterView.vue"),
        meta: {
          roles: ["no-role", "user"],
        },
      },
      {
        path: "terms-and-conditions/promoter-register",
        component: () =>
          import("@/views/UsersApp/TsAndCsPromoterRegisterView.vue"),
        meta: {
          roles: ["no-role", "user"],
        },
      },
      {
        path: "terms-and-conditions/event-create",
        component: () => import("@/views/UsersApp/TsAndCsEventCreateView.vue"),
        meta: {
          roles: ["no-role", "user"],
        },
      },
      {
        path: "terms-and-conditions/buy-tickets",
        component: () => import("@/views/UsersApp/TsAndCsBuyTicketsView.vue"),
        meta: {
          roles: ["no-role", "user"],
        },
      },
      {
        path: "terms-and-conditions/space-promotion",
        component: () =>
          import("@/views/UsersApp/TsAndCsSpacePromotionView.vue"),
        meta: {
          roles: ["no-role", "user"],
        },
      },
      {
        path: "privacy-policies",
        component: () => import("@/views/UsersApp/PrivacyPoliciesView.vue"),
        meta: {
          roles: ["no-role", "user"],
        },
      },
      {
        path: "privacy-policy",
        component: () => import("@/views/UsersApp/PrivacyPoliciesView.vue"),
        meta: {
          roles: ["no-role", "user"],
        },
      },
      {
        path: "delete-account",
        component: () => import("@/views/UsersApp/PrivacyPoliciesView.vue"),
        meta: {
          roles: ["user"],
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const roleRequired: any = to.meta.roles;
  const role = localStorage.getItem("osUserRole") || "no-role";
  if (roleRequired.includes(role)) next();
  else next(from.path);
});

export default router;
