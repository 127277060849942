// OTHER
import axios from "axios";
import { FirebaseAppCheck } from "@capacitor-firebase/app-check";

export default function compFirebaseFunctions() {
  const url = "https://us-central1-onespot-live.cloudfunctions.net/";
  // const url = "https://us-central1-onespot-live.cloudfunctions.net/";
  // const url = "http://192.168.50.182:5001/onespot-live/us-central1/";
  async function callFirebaseFunction(fn: string, body?: object) {
    try {
      let token = "";
      const getToken = await FirebaseAppCheck.getToken();
      token = getToken.token;
      const resp = await axios.post(`${url}${fn}`, body, {
        headers: { "X-Firebase-AppCheck": token },
      });
      return {
        data: resp.data,
        status: resp.status,
      };
    } catch (err) {
      console.log(err);
    }
  }
  return {
    callFirebaseFunction,
  };
}
