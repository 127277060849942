import Event from "@/types/Event";
import EventIndex from "@/types/EventIndex";
import EventTicket from "@/types/EventTicket";
import UserTicket from "@/types/UserTicket";
import SingleTicket from "@/types/SingleTicket";
import PromoCode from "@/types/PromoCode";
import PrivatePromoter from "@/types/PrivatePromoter";
import OrganizerUser from "@/types/organizerUser";
import { DocumentData } from "firebase/firestore";
import { FirebaseFirestore } from "@capacitor-firebase/firestore";
import PublicPromoter from "@/types/PublicPromoter";
import OrganizerPromoter from "@/types/OrganizerPromoter";

import compGeneralAppUtils from "@/composables/generalAppUtils";
const { returnOSFeeValues, cleanHTML } = compGeneralAppUtils();

export default function compApp() {
  function filterEventsBySpace(
    events: Event[],
    space: string,
    sortType?: string,
    order?: string
  ) {
    const exclusives = events.filter((event) => event.space == space);
    return sortEvents(exclusives, sortType, order);
  }

  function filterOSTickesEvents(
    events: Event[],
    type?: string,
    order?: string
  ) {
    const osTicketsEvents = events.filter(
      (event) => event.ticketsProvider == "os"
    );
    return sortEvents(osTicketsEvents, type, order);
  }

  function filterRecommendedEvents(
    events: Event[],
    tags: string[] | undefined
  ) {
    if (!tags) return [];
    return events.filter((event) => tags.includes(event.category));
  }

  function filterEventById(events: Event[], id: string) {
    let event = events.find((event) => event.id == id);
    if (event) return event;
    event = events.find((event) => event.customUrl == id);
    return event;
  }

  function sortEvents(events: Event[], sortType?: string, order?: string) {
    if (sortType == "random") {
      let currentIndex = events.length,
        randomIndex;
      while (currentIndex > 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [events[currentIndex], events[randomIndex]] = [
          events[randomIndex],
          events[currentIndex],
        ];
      }
      return events;
    }
    if (sortType && sortType != "random") {
      const sorted = [...events];
      sorted.sort((a, b) => {
        let valA: string | number | undefined;
        let valB: string | number | undefined;
        if (sortType == "date") {
          valA = a.dates[0].startDate;
          valB = b.dates[0].startDate;
        }
        if (sortType == "created") {
          valA = a.dateCreated;
          valB = b.dateCreated;
        }
        if (valA && valB) {
          if (valA < valB) {
            return -1;
          }
          if (valA > valB) {
            return 1;
          }
          return 0;
        } else return 0;
      });
      const reversed = [...sorted];
      reversed.reverse();
      if (order == "desc") return reversed;
      return sorted;
    }
    return events;
  }

  function mapEventObject(event: DocumentData, id: string) {
    const dates: { startDate: string; endDate: string }[] = [];
    event.dates.forEach((date: any) => {
      if (!date.startDate.includes("T")) {
        dates.push({
          startDate: `${date.startDate.replaceAll("/", "-")}T${
            date.startTime
          }:00`,
          endDate: `${date.endDate.replaceAll("/", "-")}T${date.endTime}:00`,
        });
      }
    });
    const ev: Event = {
      flyer: event.flyer,
      name: event.name,
      description: event.description,
      locationType: event.locationType,
      onlineUrl: "",
      audience: event.isAllAccess ? "allAccess" : "+18",
      dates: dates,
      category: event.category,
      tags: event.tags,
      whatsapp: event.whatsapp,
      type: "public",
      customUrl: event.urlId,
      isScheduledPublication: false,
      scheduledPublicationDate: "",
      coords: event.coords,
      dateCreated: event.dateCreated,
      formattedAddress: event.formattedAddress,
      ownerData: {
        id: event.ownerData.ownerId,
        instagram: event.ownerData.ownerInstagram,
        name: event.ownerData.ownerName,
        logo: event.ownerData.ownerLogo,
      },
      placeName: event.placeName,
      spacCharge: event.spacCharge,
      space: event.space,
      status: event.status,
      taxCharge: event.taxCharge,
      ticketsProvider: event.ticketsProvider,
      externalTicketProviderUrl: event.buyTicketsUrl,
      paidToOrganizer: event.paidToPromoter,
      id: id,
      allowPublicPromoters: event.allowPublicPromoters,
      publicPromotersTickets: event.publicPromotersTickets,
      publicPromotersMinSales: event.publicPromotersMinSales,
      proofOfPayment: event.proofOfPayment,
      salesPrice: event.salesPrice,
    };
    return ev;
  }

  function mapEventObjectV2(event: DocumentData, id: string) {
    const ev: Event = {
      flyer: event.flyer,
      name: event.name,
      description: event.description,
      locationType: event.locationType,
      onlineUrl: event.onlineUrl,
      audience: event.audience,
      dates: event.dates,
      category: event.category,
      tags: event.tags,
      whatsapp: event.whatsapp,
      type: event.type,
      customUrl: event.customUrl,
      isScheduledPublication: event.isScheduledPublication,
      scheduledPublicationDate: event.scheduledPublicationDate,
      coords: event.coords,
      dateCreated: event.dateCreated,
      formattedAddress: event.formattedAddress,
      ownerData: {
        id: event.ownerData.id,
        instagram: event.ownerData.instagram,
        name: event.ownerData.name,
        logo: event.ownerData.logo,
      },
      placeName: event.placeName,
      spacCharge: event.spacCharge,
      space: event.space,
      status: event.status,
      taxCharge: event.taxCharge,
      ticketsProvider: event.ticketsProvider,
      externalTicketProviderUrl: event.externalTicketProviderUrl,
      paidToOrganizer: event.paidToOrganizer,
      id: id,
      allowPublicPromoters: event.allowPublicPromoters,
      publicPromotersTickets: event.publicPromotersTickets,
      publicPromotersMinSales: event.publicPromotersMinSales,
      proofOfPayment: event.proofOfPayment,
      salesOS: event.salesOS,
      salesPrice: event.salesPrice,
      salesSpac: event.salesSpac,
      salesTaxes: event.salesTaxes,
    };
    return ev;
  }

  function mapEventIndex(event: DocumentData, id: string) {
    const evIndex: EventIndex = {
      name: event.name,
      description: cleanHTML(event.description),
      category: event.category,
      tags: event.tags,
      formattedAddress: event.formattedAddress,
      ownerData: {
        instagram: event.ownerData.instagram || event.ownerData.ownerInstagram,
        name: event.ownerData.name || event.ownerData.ownerName,
      },
      placeName: event.placeName,
      id: id,
    };
    return evIndex;
  }

  function mapEventTicket(ticket: DocumentData, id: string) {
    const evTx: EventTicket = {
      amount: ticket.amount,
      dateTime: ticket.dateTime.includes("T")
        ? ticket.dateTime
        : `${ticket.dateTime.replaceAll("/", "-").replaceAll(" - ", "T")}:00`,
      description: ticket.description,
      display: ticket.display,
      name: ticket.name,
      price: ticket.price,
      endDateTime: ticket.endDateTime || ticket.availableEndDate,
      startDateTime: ticket.startDateTime || ticket.availableStartDate,
      id: id,
      amountToBuy: 0,
    };
    return evTx;
  }

  function mapUserTicket(ticket: any) {
    let newDate: string | undefined = undefined;
    if (!ticket.dateTime.includes("T")) {
      const splitedDate = ticket.dateTime.split(" - ");
      newDate = `${splitedDate[0].replaceAll("/", "-")}T${splitedDate[1]}:00`;
    }
    const tx: UserTicket = {
      dateTime: newDate || ticket.dateTime,
      event: {
        flyer: ticket.event.flyer,
        id: ticket.event.id,
        name: ticket.event.name,
        placeName: ticket.event.placeName || "",
      },
      id: ticket.id,
      isCourtesy: ticket.isCourtesy,
      ownerEmail: ticket.ownerEmail,
      ownerName: ticket.ownerName,
      ownerLastName: ticket.ownerLastName,
      status: ticket.status,
      type: ticket.type,
      sold: ticket.sold,
      price: ticket.price,
      os: ticket.os,
      taxes: ticket.taxes,
      spac: ticket.spac,
      soldByPromoterData: ticket.soldByPromoterData
        ? {
            commission: ticket.soldByPromoterData.commission,
            promoterId: ticket.soldByPromoterData.promoterId,
            promoCode: ticket.soldByPromoterData.promoCode,
          }
        : undefined,
    };
    return tx;
  }

  function mapSingleTicket(ticket: any) {
    const tx: SingleTicket = {
      amount: ticket.amount,
      dateTime: ticket.dateTime.includes("T")
        ? ticket.dateTime
        : `${ticket.dateTime.replaceAll("/", "-").replaceAll(" - ", "T")}:00`,
      description: ticket.description,
      name: ticket.name,
      price: ticket.price,
      display: ticket.display || false,
      availableStartDate:
        "availableStartDate" in ticket
          ? ticket.availableStartDate
          : ticket.startDateTime
          ? `${ticket.startDateTime
              .replaceAll("/", "-")
              .replaceAll(" - ", "T")}:00`
          : ticket.startDateTime,
      availableEndDate:
        "availableEndDate" in ticket
          ? ticket.availableEndDate
          : ticket.endDateTime
          ? `${ticket.endDateTime
              .replaceAll("/", "-")
              .replaceAll(" - ", "T")}:00`
          : ticket.endDateTime,
      id: ticket.id || "",
    };
    return tx;
  }

  function mapPromoCode(promo: any) {
    const pmCode: PromoCode = {
      code: promo.code,
      percent: promo.percent,
      validFrom: promo.validFrom,
      validTo: promo.validTo,
      id: promo.id,
    };
    return pmCode;
  }

  function mapOrganizer(organizer: any, id: string) {
    const org: OrganizerUser = {
      adminEmail: organizer.adminEmail,
      adminName: organizer.adminName,
      adminLastname: organizer.adminLastname || organizer.adminLastName,
      ruc: organizer.ruc,
      followers: organizer.followers || 0,
      logo: organizer.logo,
      instagram: organizer.instagram,
      name: organizer.name,
      noticeOfOperations: organizer.noticeOfOperations,
      space: organizer.space,
      status: organizer.status,
      type: "organizer",
      id: id,
    };
    return org;
  }

  function mapOrganizerPromoter(promoter: any) {
    const privatePromo: OrganizerPromoter = {
      promoterId: promoter.promoterId,
      promoterEmail: promoter.promoterEmail,
      promoterFullName: promoter.promoterFullName,
    };
    return privatePromo;
  }

  function mapPublicPromoter(request: any, id: string) {
    const rq: PublicPromoter = {
      event: request.event,
      promoterEmail: request.promoterEmail,
      promoterId: request.promoterId,
      status: request.status,
      promoterName: request.promoterName,
      id: id,
      type: request.type,
      achFile: request.achFile,
    };
    return rq;
  }

  function mapPrivatePromoter(request: any, id: string) {
    const rq: PrivatePromoter = {
      event: request.event,
      promoterEmail: request.promoterEmail,
      promoterId: request.promoterId,
      promoterName: request.promoterName,
      minSales: request.minSales,
      tickets: request.tickets,
      type: request.type,
      achFile: request.achFile,
      id: id,
    };
    return rq;
  }

  function mapLabelValue(item: string, list: any) {
    const filtered = list.find((i: { value: string }) => i.value == item);
    if (filtered) return filtered;
    return { label: item, value: item };
  }

  function userFollowsEvent(
    eventId: string | undefined,
    userEventsFollowing: string[] | undefined
  ) {
    if (eventId && userEventsFollowing)
      return userEventsFollowing.includes(eventId);
    return false;
  }

  function validateEmailFormat(email: string) {
    const validEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return validEmail.test(email);
  }

  function calculateTotals(tickets: UserTicket[]) {
    const totals = {
      sold: 0,
      tax: 0,
      spac: 0,
      os: 0,
      soldAmount: 0,
      courtesiesAmount: 0,
      byPromoters: 0,
      directSale: 0,
      osFee: 0,
      total: 0,
    };
    tickets.forEach((sale: UserTicket) => {
      totals.sold += sale.price;
      totals.tax += sale.taxes;
      totals.spac += sale.spac;
      totals.os += sale.os;
      totals.osFee += returnOSFeeValues().serv * sale.price;
      if (!sale.isCourtesy) totals.soldAmount++;
      else totals.courtesiesAmount++;
      if (sale.soldByPromoterData?.promoterId) totals.byPromoters++;
      else if (!sale.isCourtesy) totals.directSale++;
    });
    totals.total = totals.sold + totals.tax + totals.spac - totals.osFee;
    return totals;
  }

  function sortTicketsBySoldDate(txList: UserTicket[]) {
    const txl = [...txList];
    txl.sort((a: any, b: any) => {
      return b.sold - a.sold;
    });
    return txl;
  }

  async function getEventVersion(eventId: string) {
    const { snapshot } = await FirebaseFirestore.getDocument({
      reference: `eventsV2/${eventId}`,
    });
    if (snapshot.data) return "eventsV2";
    else return "events";
  }

  return {
    mapEventObject,
    mapEventObjectV2,
    mapEventIndex,
    mapUserTicket,
    mapEventTicket,
    mapSingleTicket,
    mapPromoCode,
    mapPublicPromoter,
    mapPrivatePromoter,
    mapOrganizerPromoter,
    mapOrganizer,
    filterEventsBySpace,
    filterOSTickesEvents,
    filterRecommendedEvents,
    filterEventById,
    sortEvents,
    mapLabelValue,
    userFollowsEvent,
    validateEmailFormat,
    calculateTotals,
    sortTicketsBySoldDate,
    getEventVersion,
  };
}
