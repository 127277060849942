<template>
  <ion-page>
    <!-- HEADER -->
    <ion-header>
      <ion-toolbar>
        <ion-grid>
          <ion-row class="ion-justify-content-center ion-align-items-center">
            <ion-col size-lg="8" size-xs="12" style="padding: 0">
              <ion-row class="ion-align-items-center">
                <ion-img
                  :src="require('@/assets/images/os-logo.png')"
                  style="width: 30px; cursor: pointer"
                  @click="$router.push('/')"
                  alt="One Spot Logo"
                />
                <div
                  style="margin-left: 5px; font-size: 16px; cursor: pointer"
                  @click="$router.push('/')"
                >
                  <span class="os-bold">ONE</span>
                  <span>&nbsp;</span>
                  <span>SPOT</span>
                </div>

                <ion-col />
                <ion-button
                  fill="clear"
                  size="small"
                  color="dark"
                  id="click-trigger"
                >
                  <ion-icon
                    :icon="personCircleOutline"
                    style="font-size: 2em"
                  ></ion-icon>
                </ion-button>
                <ion-popover
                  trigger="click-trigger"
                  trigger-action="click"
                  size="auto"
                >
                  <ion-content class="ion-padding" v-if="!userData">
                    <!-- EMAIL -->
                    <ion-row class="ion-justify-content-center mb-md">
                      <ion-col>
                        <input
                          type="email"
                          class="form__field"
                          v-model="email"
                        />
                        <label class="form__label">Correo electrónico</label>
                      </ion-col>
                    </ion-row>
                    <!-- /EMAIL -->

                    <!-- PASSWORD -->
                    <ion-row class="ion-justify-content-center mb-md">
                      <ion-col>
                        <input
                          type="password"
                          class="form__field"
                          v-model="pass"
                        />
                        <label class="form__label">Contraseña</label>
                      </ion-col>
                    </ion-row>
                    <!-- /PASSWORD -->

                    <!-- LOGIN BTN -->
                    <ion-button
                      color="os-pink"
                      expand="block"
                      size="small"
                      class="mb-sm"
                      @click="login()"
                    >
                      Iniciar sesión
                    </ion-button>
                    <!-- /LOGIN BTN -->

                    <!-- LOGIN WITH GOOGLE -->
                    <ion-button
                      expand="block"
                      color="medium"
                      fill="outline"
                      size="small"
                      class="mb-md"
                      @click="loginWithGoogle()"
                      v-if="!isInstagram"
                    >
                      <ion-img
                        :src="require('@/assets/images/google.svg')"
                        class="mr-sm"
                        style="width: 20px"
                      />Google</ion-button
                    >
                    <!-- /LOGIN WITH GOOGLE -->

                    <!-- SEPARATOR -->
                    <hr
                      style="
                        height: 1px;
                        background-color: lightgrey;
                        margin: 5px;
                      "
                    />
                    <!-- /SEPARATOR -->

                    <!-- REGISTER LINK -->
                    <div
                      class="text-caption text-center text-os-blue mt-md mb-md"
                      style="cursor: pointer"
                      @click="$router.push('/register-with-email')"
                    >
                      <u>Regístrate</u>
                    </div>
                    <div class="text-caption text-center">
                      <a
                        href="/terms-and-conditions/user-register"
                        target="_blank"
                        class="text-os-pink"
                        >Términos y condiciones</a
                      >
                      para registro de usuario
                    </div>
                    <!-- /REGISTER LINK -->
                  </ion-content>
                  <ion-content class="ion-padding" v-else>
                    <div class="text-body">
                      Bienvenido,
                      <span class="os-bold">{{ userData.name }}</span>
                    </div>
                    <div class="text-caption mb-md">{{ userData.email }}</div>
                    <ion-button
                      size="small"
                      color="os-pink"
                      expand="block"
                      class="mb-sm"
                      @click="$router.push('/tickets')"
                    >
                      <ion-icon :icon="ticketOutline" slot="start"></ion-icon>
                      Mis boletos
                    </ion-button>
                    <ion-button
                      size="small"
                      color="light"
                      expand="block"
                      @click="authStore.logoutUser()"
                    >
                      Cerrar sesión
                    </ion-button>
                  </ion-content>
                </ion-popover>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
      <ion-toolbar v-if="showSegmentTabs">
        <ion-segment :value="selectedTab" v-model="selectedTab">
          <ion-segment-button value="available">Disponibles</ion-segment-button>
          <ion-segment-button value="consumed">Consumidos</ion-segment-button>
          <ion-segment-button value="expired">Expirados</ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>
    <!-- /HEADER -->
    <ion-content>
      <ion-router-outlet></ion-router-outlet>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
// IONIC / VUE IMPORTS
import { ref, onBeforeMount, computed } from "vue";
import { personCircleOutline, ticketOutline } from "ionicons/icons";
import { useRoute } from "vue-router";

// STORES
import { storeToRefs } from "pinia";
import { useAppStore } from "@/stores/AppStore";
import { useAuthStore } from "@/stores/AuthStore";
const appStore = useAppStore();
const authStore = useAuthStore();
const { userData } = storeToRefs(authStore);
const { selectedTab } = storeToRefs(appStore);

import {
  IonPage,
  IonRouterOutlet,
  IonHeader,
  IonToolbar,
  IonGrid,
  IonRow,
  IonImg,
  IonCol,
  IonContent,
  IonPopover,
  IonSegment,
  IonSegmentButton,
} from "@ionic/vue";

// REFS
const route = useRoute();
const email = ref("");
const pass = ref("");
const isInstagram = ref(false);

// METHODS
const login = async () => {
  try {
    await authStore.loginUser(email.value, pass.value);
    email.value = "";
    pass.value = "";
  } catch (err) {
    console.log(err);
  }
};

const loginWithGoogle = async () => {
  await authStore.loginUserWithGoogle();
};

// COMPUTED
const showSegmentTabs = computed(() => {
  if (route.fullPath == "/tickets") return true;
  else return false;
});

// HOOKS
onBeforeMount(async () => {
  // CHECK IF IS IG BROWSER
  const ua = navigator.userAgent;
  isInstagram.value = ua.indexOf("Instagram") > -1 ? true : false;
  // END CHECK IF IS IG BROWSER
  appStore.$patch({ globalLoading: true });
  await appStore.getAllPublishedEvents();
  appStore.$patch({ globalLoading: false });
});
</script>
