import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { getPlatforms } from "@ionic/vue";

export default function compAnalytics() {
  async function logEvent(action: string, eventId: string) {
    const plt = getPlatforms();
    let platform = "Other";
    if (plt.includes("android")) platform = "Android";
    if (plt.includes("ios")) platform = "iOS";
    if (plt.includes("desktop")) platform = "Desktop";
    if (!action || !eventId) return;
    console.log(`Sending Analytics Event: ${action} - ${eventId}`);
    FirebaseAnalytics.logEvent({
      name: action,
      params: {
        event_id: eventId,
        platform: platform,
      },
    });
  }
  return {
    logEvent,
  };
}
