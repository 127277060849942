export default function compAuth() {
  function formatLoginAuthErrors(code: string) {
    const err = authErrCodes.find((c) => code.includes(c.code));
    if (err) return err.value;
    return code;
  }
  const authErrCodes = [
    {
      code: "wrong-password",
      value: "Contraseña incorrecta.",
    },
    {
      code: "user-not-found",
      value: "Usuario no encontrado.",
    },
    {
      code: "invalid-email",
      value: "El correo ingresado no es válido.",
    },
    {
      code: "weak-password",
      value: "Tu contraseña es muy débil, debe incluir al menos 6 caracteres.",
    },
    {
      code: "email-already-in-use",
      value: "Esta dirección de correo ya fue registrada.",
    },
    {
      code: "too-many-requests",
      value: "Has excedido la cantidad de intentos.",
    },
    {
      code: "user-disabled",
      value: "Esta cuenta ha sido deshabilitada.",
    },
  ];
  return {
    formatLoginAuthErrors,
  };
}
