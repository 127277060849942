<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script setup lang="ts">
// CAPACITOR PLUGINS
import { FirebaseAppCheck } from "@capacitor-firebase/app-check";

// IONIC & VUE
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { onBeforeMount } from "vue";

// STORES
import { useAuthStore } from "@/stores/AuthStore";
const authStore = useAuthStore();

// COMPOSABLES
import compAnalytics from "@/composables/analyticsComp";
const { logEvent } = compAnalytics();

// LC HOOKS
onBeforeMount(async () => {
  // CHECK IF AUTHENTICATED
  authStore.checkIfAuthenticated();

  // APP CHECK
  const debug = window.location.href.includes("localhost") ? true : false;
  await FirebaseAppCheck.initialize({
    siteKey: "6Lck7S8qAAAAAJ-VyJQosij7wjQmUERlJ79pCZfI",
    debug: debug,
  });
  await FirebaseAppCheck.setTokenAutoRefreshEnabled({ enabled: true });

  // SEND ANALYTICS EVENTS QUEUE
  setInterval(() => {
    const eventsToSend = JSON.parse(localStorage.getItem("events") || "[]");
    localStorage.setItem("events", "[]");
    eventsToSend.forEach((event: any) => {
      logEvent(event.action, event.eventId);
    });
  }, 1000);
});
</script>

<style>
@import "./theme/os-styles.css";
</style>
