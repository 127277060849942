import { defineStore } from "pinia";

import { FirebaseFirestore } from "@capacitor-firebase/firestore";

import Event from "@/types/Event";
import EventIndex from "@/types/EventIndex";
import EventTicket from "@/types/EventTicket";
import PublicPromoter from "@/types/PublicPromoter";
import OrganizerUser from "@/types/organizerUser";

import appComp from "@/composables/appComp";
import generalAppComp from "@/composables/generalAppUtils";
const { showMessage } = generalAppComp();

const {
  mapEventObject,
  mapEventObjectV2,
  mapEventIndex,
  mapOrganizer,
  filterEventsBySpace,
  filterOSTickesEvents,
  mapEventTicket,
  getEventVersion,
  sortEvents,
} = appComp();

export const useAppStore = defineStore("appStore", {
  state: () => ({
    globalLoading: false,
    allEvents: <Event[]>[],
    allExclusiveEventsCarousel: <Event[]>[],
    allOSTicketsEventsCarousel: <Event[]>[],
    allEventsIndex: <EventIndex[]>[],
    allOrganizers: <OrganizerUser[]>[],
    selectedTab: <"available" | "consumed" | "expired">"available",
  }),
  actions: {
    async getAllPublishedEvents() {
      try {
        const [v1, v2] = await Promise.all([
          this.getV1Events(),
          this.getV2Events(),
        ]);
        const allEv: Event[] = [];
        const allEvIndex: EventIndex[] = [];
        v1.forEach((event) => {
          try {
            if (!event.data) return;
            const ev = mapEventObject(event.data, event.id);
            const evIndex = mapEventIndex(event.data, event.id);
            allEv.push(ev);
            allEvIndex.push(evIndex);
          } catch (err) {
            console.log(err);
          }
        });
        v2.forEach((event) => {
          try {
            if (!event.data) return;
            const ev = mapEventObjectV2(event.data, event.id);
            const evIndex = mapEventIndex(event.data, event.id);
            allEv.push(ev);
            allEvIndex.push(evIndex);
          } catch (err) {
            console.log(err);
          }
        });
        this.allEvents = sortEvents(allEv, "date");
        this.allEventsIndex = allEvIndex;
        this.allExclusiveEventsCarousel = filterEventsBySpace(
          allEv,
          "hot",
          "random"
        );
        this.allOSTicketsEventsCarousel = filterOSTickesEvents(allEv, "random");
      } catch (err) {
        console.log("Ocurrió un error buscando los eventos.");
      }
    },

    async getV1Events() {
      const { snapshots } = await FirebaseFirestore.getCollection({
        reference: "events",
        compositeFilter: {
          type: "and",
          queryConstraints: [
            {
              type: "where",
              fieldPath: "status",
              opStr: "==",
              value: "approved",
            },
          ],
        },
      });
      return snapshots;
    },

    async getV2Events() {
      const { snapshots } = await FirebaseFirestore.getCollection({
        reference: "eventsV2",
        compositeFilter: {
          type: "and",
          queryConstraints: [
            {
              type: "where",
              fieldPath: "status",
              opStr: "==",
              value: "approved",
            },
          ],
        },
      });
      return snapshots;
    },

    async getAllApprovedOrganizers() {
      const allOrganizers: OrganizerUser[] = [];
      const { snapshots } = await FirebaseFirestore.getCollection({
        reference: "users",
        compositeFilter: {
          type: "and",
          queryConstraints: [
            {
              type: "where",
              fieldPath: "type",
              opStr: "==",
              value: "organizer",
            },
            {
              type: "where",
              fieldPath: "status",
              opStr: "==",
              value: "approved",
            },
          ],
        },
      });
      snapshots.forEach((org) =>
        allOrganizers.push(mapOrganizer(org.data, org.id))
      );
      this.allOrganizers = allOrganizers;
      this.allOrganizers.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    },

    async getAllOrganizers() {
      const allOrganizers: OrganizerUser[] = [];
      const { snapshots } = await FirebaseFirestore.getCollection({
        reference: "users",
        compositeFilter: {
          type: "and",
          queryConstraints: [
            {
              type: "where",
              fieldPath: "type",
              opStr: "==",
              value: "organizer",
            },
          ],
        },
      });
      snapshots.forEach((org) =>
        allOrganizers.push(mapOrganizer(org.data, org.id))
      );
      this.allOrganizers = allOrganizers;
      this.allOrganizers.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    },

    async getEventById(id: string) {
      try {
        const [v1, v2] = await Promise.all([
          this.getEventByIdV1(id),
          this.getEventByIdV2(id),
        ]);
        if (v2?.data) return mapEventObjectV2(v2.data, v2.id);
        if (v1?.data) return mapEventObject(v1.data, v1.id);
      } catch (err) {
        console.log("Ocurrió un error consultando los datos del evento");
      }
    },

    async getEventByIdV1(id: string) {
      const { snapshot } = await FirebaseFirestore.getDocument({
        reference: `events/${id}`,
      });
      if (snapshot.data) return snapshot;
      const { snapshots } = await FirebaseFirestore.getCollection({
        reference: "events",
        compositeFilter: {
          type: "and",
          queryConstraints: [
            {
              type: "where",
              fieldPath: "urlId",
              opStr: "==",
              value: id,
            },
          ],
        },
      });
      if (snapshots.length > 0) return snapshots[0];
      return null;
    },

    async getEventByIdV2(id: string) {
      const { snapshot } = await FirebaseFirestore.getDocument({
        reference: `eventsV2/${id}`,
      });
      if (snapshot.data) return snapshot;
      const { snapshots } = await FirebaseFirestore.getCollection({
        reference: "eventsV2",
        compositeFilter: {
          type: "and",
          queryConstraints: [
            {
              type: "where",
              fieldPath: "customUrl",
              opStr: "==",
              value: id,
            },
          ],
        },
      });
      if (snapshots.length > 0) return snapshots[0];
      return null;
    },

    async getEventTickets(eventId: string, bypassDisplayFilter = false) {
      const version = await getEventVersion(eventId);
      let filter: any;
      if (!bypassDisplayFilter)
        filter = [
          {
            type: "where",
            fieldPath: "display",
            opStr: "==",
            value: true,
          },
        ];
      else filter = [];
      const { snapshots } = await FirebaseFirestore.getCollection({
        reference: `${version}/${eventId}/tickets`,
        compositeFilter: {
          type: "and",
          queryConstraints: filter,
        },
      });
      if (snapshots.length > 0) {
        const allEvTx: EventTicket[] = [];
        snapshots.forEach((tx) => {
          if (!tx.data) return;
          allEvTx.push(mapEventTicket(tx.data, tx.id));
        });
        return allEvTx;
      }
      return [];
    },

    async sendPromoterRequest(
      promoterId: string,
      promoterFullName: string,
      promoterEmail: string,
      eventId: string
    ) {
      const promoterRequest: PublicPromoter = {
        promoterId: promoterId,
        promoterName: promoterFullName,
        promoterEmail: promoterEmail,
        event: eventId,
        status: "pending",
        type: "public",
      };
      try {
        await FirebaseFirestore.addDocument({
          reference: "promoters",
          data: promoterRequest,
        });
        showMessage("success", "Solicitud enviada con éxito.");
        return promoterRequest;
      } catch (err) {
        alert("Ocurrió un error.");
      }
    },
  },
});
